import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Chip,
  CircularProgress,
  InputAdornment,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import {
  isTypeCodeableConcept,
  isTypeCoding,
} from "../../../../types/codeableConcept";
import { isCodeableConcept } from "../../../../utils/DataUtils";
import { createSlug } from "../../../../utils/createSlug";

type Props = {
  disabled?: boolean;
  freeOptions?: boolean;
  onItemSelection: (val: any, reason: string) => void;
  value?: any;
  options: any[];
  optionType: string;
  loading?: boolean;
  fieldLabel: string;
  required?: boolean;
  setInputValue: (value: string) => void;
  size?: "small" | "medium";
  variant?: "standard" | "filled" | "outlined";
  getOptionValue?: (a: any) => void;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  storeInitialValue: (value: any) => void;
  disableCloseOnSelect?: boolean;
  getOptionLabel?: (a: any) => string;
  getValueLabel?: (a: any) => string;
};

function MultipleInput({
  disabled,
  freeOptions,
  onItemSelection,
  value,
  options,
  optionType,
  loading,
  fieldLabel,
  required,
  setInputValue,
  size,
  variant,
  getOptionValue,
  isOptionEqualToValue,
  storeInitialValue,
  disableCloseOnSelect,
  getOptionLabel,
  getValueLabel,
}: Props) {
  const [isInitialValuePresent, setIsInitialValuePresent] = useState(false);

  useEffect(() => {
    if (
      value &&
      value.length > 0 &&
      options &&
      options.length > 0 &&
      !isInitialValuePresent
    ) {
      const convertedValue = createValue(value, options, optionType);
      storeInitialValue(convertedValue);
      setComponentValue(convertedValue);
      setIsInitialValuePresent(true);
    }
  }, [value, options]);

  const convertToCode = (value) => {
    if (!value) return undefined;

    if (isTypeCodeableConcept(value)) {
      return {
        code: value.coding[0].code,
        display: value.coding[0].display,
      };
    }
    return value;
  };

  const createValue = (value, options, optionType) => {
    if (value && value.length > 0) {
      if (optionType === "snomed") {
        return value.map((v) => {
          const option = options.find((o) =>
            isTypeCodeableConcept(v)
              ? o.snomedCID === v?.coding[0].code
              : isTypeCoding(v)
                ? o.snomedCID === v?.code
                : o.snomedCID === v?.identifier,
          );
          return option || v;
        });
      }
      return value.map((v) => {
        const option = options.find((o) =>
          isTypeCodeableConcept(v)
            ? o.code === v?.coding[0].code
            : isTypeCoding(v)
              ? o.code === v?.code
              : o.code === v?.identifier,
        );
        return option || convertToCode(v);
      });
    }
    return undefined;
  };

  const [componentValue, setComponentValue] = useState<any>(
    value ? createValue(value, options, optionType) : [], // empty array because undefined && null can't be updated
  );

  const checkIfInputHasValue = (value) => {
    const VALUE_IS_REQUIRED = true;
    if (value) {
      if (optionType === "snomed" && Object.keys(value).length > 0) {
        return isCodeableConcept(value[0])
          ? value[0].coding[0].code.length === 0
          : value[0]?.identifier
            ? value[0].identifier.length === 0
            : VALUE_IS_REQUIRED;
      }
      return value.length === 0;
    }
    return VALUE_IS_REQUIRED;
  };

  const selectInputValue = (val, reason) => {
    setIsInitialValuePresent(true);
    if (val) {
      const v = getOptionValue ? val.map((v) => getOptionValue(v)) : val;
      onItemSelection(val, reason);
      setComponentValue(v);
    } else {
      onItemSelection(undefined, reason);
    }
  };

  const getLabel = (option) => {
    if (getOptionLabel) {
      return getOptionLabel(option);
    }
    if (optionType === "snomed") {
      return option.snomedFSN;
    }
    return option.display;
  };

  const renderChipsTags = (value, getTagProps) =>
    value.map((option, index) => {
      const valueLabel = getValueLabel(option);
      const label = getLabel(option);
      return <Chip label={valueLabel || label} {...getTagProps({ index })} />;
    });

  const cleanFreeOptions = (
    val: any,
    reason: AutocompleteChangeReason,
    detail: AutocompleteChangeDetails<any>,
  ) => {
    if (reason === "clear") return [];

    const display =
      optionType === "snomed" ? detail.option.snomedFSN : detail.option.display;
    const code =
      optionType === "snomed" ? detail.option.snomedCID : detail.option.code;
    if (
      code &&
      display &&
      val.length > 0 &&
      freeOptions &&
      `${code}`.startsWith("other") &&
      reason === "selectOption"
    ) {
      const newDisplay = display.match(/"([^"]+)"/)[1]; // get text between quotes
      const newDetail =
        optionType === "snomed"
          ? {
              snomedCID: code,
              snomedFSN: newDisplay,
            }
          : {
              code,
              display: newDisplay,
            };
      val.pop();
      val.push(newDetail);
      return val;
    }
    return val;
  };

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      options={options}
      getOptionLabel={getLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={(e, val, reason, detail) => {
        const valueArray = cleanFreeOptions(val, reason, detail);
        selectInputValue(valueArray, reason);
      }}
      onInputChange={(event, newInput) =>
        setInputValue(isEmpty(newInput.trim()) ? "" : newInput.trim())
      }
      value={componentValue}
      renderTags={renderChipsTags}
      renderInput={(params) => (
        <TextField
          {...{
            ...params,
            ...(loading
              ? {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={20} thickness={6} />
                      </InputAdornment>
                    ),
                  },
                }
              : null),
          }}
          label={fieldLabel}
          variant={variant || "outlined"}
          size={size || "medium"}
          InputLabelProps={{ shrink: true }}
          required={required}
          inputProps={{
            ...params.inputProps,
            required: required && !(componentValue?.length >= 1),
          }}
        />
      )}
      filterSelectedOptions
      filterOptions={(options, params) => {
        const filtered = createFilterOptions()(options, params);
        const { inputValue } = params;

        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) =>
            inputValue.toLowerCase() === getLabel(option).toLowerCase(),
        );
        if (
          inputValue !== "" &&
          !isExisting &&
          freeOptions &&
          !filtered.length
        ) {
          const newOption =
            optionType === "snomed"
              ? {
                  snomedCID: createSlug(inputValue, "other"),
                  snomedFSN: `Add "${inputValue}"`,
                }
              : {
                  code: createSlug(inputValue, "other"),
                  display: `Add "${inputValue}"`,
                };
          filtered.push(newOption);
        }
        return filtered;
      }}
      id="tags-standard"
      clearOnBlur={false}
      disableCloseOnSelect={disableCloseOnSelect}
      size={size ?? "small"}
    />
  );
}

export default MultipleInput;
