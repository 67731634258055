import { Typography } from "@mui/material";
import { useSelector } from "../../store";

interface OrganizationTierProps {
  trialColor?: string;
  premiumColor?: string;
}

const OrganizationTier = ({
  premiumColor,
  trialColor,
}: OrganizationTierProps) => {
  const currentOrganization = useSelector(
    (state) => state.user.currentOrganization,
  );

  return (
    <>
      {currentOrganization?.adminSettings?.tier === "PREMIUM" && (
        <Typography
          sx={{ fontStyle: "italic" }}
          style={{
            marginTop: -20,
            marginLeft: 10,
            color: premiumColor || "#FFD700",
          }}
        >
          Premium
        </Typography>
      )}
      {currentOrganization?.adminSettings?.tier === "TRIAL" && (
        <Typography
          sx={{ fontStyle: "italic" }}
          style={{
            marginTop: -20,
            marginLeft: 10,
            color: trialColor || "#37fd12",
          }}
        >
          Trial
        </Typography>
      )}
    </>
  );
};

export default OrganizationTier;
