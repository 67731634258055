import { medicalApiPaths, medicalsApi } from "../../../../api/medicalsApi";
import { formatDateTime } from "../../../../utils/DateUtils";
import { CONDITION_CATEGORY } from "../../Constants";
import { vitalSignsCategory, vitalSignsCode } from "../cards/vitals/constants";

const acceptedChiefComplaintStatuses = [
  "registered",
  "preliminary",
  "final",
  "amended",
];

export const fetchComplaints = async (encounterId, patientId) => {
  const query = {
    encounter: encounterId,
    subject: patientId,
    status: acceptedChiefComplaintStatuses,
    category: "chief-complaint",
  };
  return await medicalsApi.findEntities(query, medicalApiPaths.observations);
};

export const fetchAllergies = async (patientId, encounterId?, query = {}) => {
  let dbQuery: Record<string, unknown> = { ...query, patient: patientId };
  if (encounterId) {
    dbQuery = { ...dbQuery, encounter: encounterId };
  }
  return await medicalsApi.findAllergies(dbQuery);
};

export const fetchPatientHistory = async (patientId) => {
  const query = { patient: patientId };
  return await medicalsApi.getPatientHistory(query);
};

export const fetchMedicalConditions = async (patientId) => {
  if (!patientId) return;
  return await medicalsApi.findEntities(
    {
      patient: patientId,
      clinicalStatus: ["active", "recurrence", "relapse", "remission"],
      verificationStatus: [
        "unconfirmed",
        "provisional",
        "differential",
        "confirmed",
      ],
    },
    medicalApiPaths.conditions,
  );
};

export const fetchEncounterDiagnoses = async (patientId, encounterId) => {
  if (patientId) {
    const query = {
      patient: patientId,
      encounter: encounterId,
      category: CONDITION_CATEGORY.ENCOUNTER_DIAGNOSIS,
      verificationStatus: [
        "unconfirmed",
        "provisional",
        "differential",
        "confirmed",
        "refuted",
      ],

      //clinicalStatus: ["active", "recurrence", "relapse"],
    };
    return await medicalsApi.findEntities(query, medicalApiPaths.conditions);
  }
};

export const fetchCurrentMedications = async (patientId) => {
  const query = {
    patient: patientId,
    status: ["active", "intended", "on-hold"],
  };
  return await medicalsApi.findMedicationStatements(query);
};

export const fetchProgressNotes = async (patientId, encounterId) => {
  const query = {
    patient: patientId,
    encounter: encounterId,
    "exclude-types": ["55107-7"],
  };
  return await medicalsApi.findEntities(query, "compositions");
};

export const fetchAddendums = async (patientId, encounterId) => {
  const query = {
    patient: patientId,
    encounter: encounterId,
    type: "55107-7",
  };
  return await medicalsApi.findEntities(query, "compositions");
};

export const fetchVitals = async (patientId, encounterId) => {
  const query = {
    subject: patientId,
    code: vitalSignsCode,
    category: vitalSignsCategory,
    encounter: encounterId,
  };

  return await medicalsApi.findEntities(query, medicalApiPaths.observations);
};

export const createVitalDisplay = (data) => {
  const fragments = [];
  if (data) {
    data.forEach((d) => {
      const entries = d?.hasMember?.map((m) => m.display);
      const effDate = new Date(d.meta.created);
      entries?.unshift(formatDateTime(effDate));
      fragments.push({
        text: entries?.join(" · "),
        id: d.id,
        final: d.status && d.status === "final",
      });
    });
  }
  return fragments;
};

export const setSingleColumns = (flags) => {
  const columns = [];
  singleColumnTemplate.forEach((t) => {
    if (flags[`allow${t}`]) {
      columns.push(t);
    } else columns.push("none");
  });

  return columns;
};

export const setDualColumns = (flags) => {
  const columns = [];
  dualColumnTemplate.forEach((t) => {
    if (flags[`allow${t}`]) {
      columns.push(t);
    } else columns.push("none");
  });

  return columns;
};

export const getRandomInt = (max?: number): number =>
  Math.floor(Math.random() * (max ?? 1000000));

const singleColumnTemplate = [
  "ChiefComplaints",
  "Allergies",
  "ImmunizationFeature",
  "PatientHistories",
  "CurrentMedications",
  "Vitals",
  "ReviewOfSystems",
  "PhysicalAssessments",
  "Attachments",
  "ProgressNotes",
  "Procedures",
  "Diagnoses",
];

const dualColumnTemplate = [
  "ChiefComplaints",
  "ReviewOfSystems",
  "ImmunizationFeature",
  "PhysicalAssessments",
  "Allergies",
  "none",
  "PatientHistories",
  "none",
  "CurrentMedications",
  "none",
  "Vitals",
  "Attachments",
  "none",
  "ProgressNotes",
  "none",
  "Addendums",
  "none",
  "Procedures",
  "none",
  "Diagnoses",
];
