import { get, startCase } from "lodash";
import { patientRelationships } from "../slices/options/family";
import { identifierTypes } from "../slices/options/misc";
import { AppointmentParticipant } from "../types/appoitment/appointmentBase";

const patientRelationshipMap = patientRelationships.reduce(function (map, obj) {
  map[obj.code] = obj.display;
  return map;
}, {});

export const generateAppointmentDescription = (
  participant: AppointmentParticipant[],
) => {
  if (Array.isArray(participant)) {
    return participant.map((p) => p.actor?.display.split("-")[0]).join(" · ");
  }

  return "";
};

export const generateHumanDisplayName = (humanName) => {
  if (!humanName) return "";
  const { firstName, given, middleName, family, prefix, suffix } =
    humanName ?? {};

  const p1 = [
    prefix,
    given !== undefined && given !== "" ? given : firstName,
    middleName,
    family,
  ]
    .filter((e) => e && e !== "")
    .join(" ");
  const p2 = [suffix].filter((e) => e && e !== "").join(" ");

  const arr: string[] = [];
  if (p1 !== "") arr.push(p1);
  if (p2 !== "") arr.push(p2);

  return arr.join(", ");
};

export const generatePatientIdentifier = (identifier) => {
  if (!identifier) return "";

  const { type, value, use, assigner } = identifier;

  const typeVal = parseTextField(type);

  const typeDisplay = identifierTypes.find((i) => i.code === typeVal);

  return [
    use ? startCase(use) : undefined,
    typeDisplay ? startCase(typeDisplay.display) : type,
    assigner ? startCase(assigner.display || assigner) : undefined,
    value,
  ]
    .filter((e) => e && e !== "")
    .join(" · ");
};

export const getDerivedMedicationForm = (form) => {
  if (form) {
    const forms = form.split(",");
    return forms[0].trim().toUpperCase();
  }
  return undefined;
};

export const generatePatientAddress = (address) => {
  if (!address) return "";
  const { line, city, state, country, postalCode, use } = address;
  const useVal = parseTextField(use);
  return `${useVal ? `${startCase(useVal)}: ` : ""}${line ? `${line}` : ""}${
    city ? ` · ${city}` : ""
  }${state ? ` · ${state}` : ""}${country ? ` · ${country}` : ""}${
    postalCode ? ` · ${postalCode}` : ""
  }`;
};

export const renderAvailableTimes = (availability) => {
  const { daysOfWeek, availableStartTime, availableEndTime } = availability;

  //Hh:mm:ss to hh:mm format with removed seconds
  const convertTO12HrFormat = (time) => {
    return time.split(":")[0] > 12
      ? `${Number(time.substring(0, 5).split(":")[0]) - 12}:${
          time.substring(0, 5).split(":")[1]
        } PM`
      : `${time.substring(0, 5)} AM`;
  };

  return `${daysOfWeek.map((d) => startCase(d)).join(", ")}${
    availableStartTime ? `: ${convertTO12HrFormat(availableStartTime)}` : ""
  }${availableEndTime ? ` - ${convertTO12HrFormat(availableEndTime)}` : ""}`;
};

export const generateTelecomString = (telecom) => {
  const { value, use, system } = telecom;
  return `${use ? `${startCase(use)}` : ""}${
    system ? ` · ${startCase(system)}` : ""
  }${value ? ` · ${value}` : ""}`;
};

export const renderPatientMedicalContact = (
  contact: PatientContact,
): string => {
  const { name, relationship, telecom } = contact;
  const relDisplay = relationship ? patientRelationshipMap[relationship] : "";
  const nameDisplay = name && name[0] ? generateHumanDisplayName(name[0]) : "";
  const telecomDisplay = telecom
    ?.map((t) => `${t.system ? startCase(t.system) : ""}: ${t.value || ""}`)
    .filter((part) => part.trim() !== ":") // Remove entries that result in just ":"
    .join(", ");

  return [relDisplay, nameDisplay, telecomDisplay].filter(Boolean).join(" - ");
};

export const createReference = (type, id, display?) => {
  return {
    reference: `${type}/${id}`,
    type: type,
    identifier: id,
    display,
  };
};

export const generateMedicationDisplay = (medication) => {
  const { medicineName, form } = medication;
  const parts = [];
  if (medicineName) {
    parts.push(startCase(medicineName.toLowerCase()));
  }

  if (form) {
    parts.push(startCase(form.toLowerCase()));
  }

  return parts.join(" · ");
};

export const generateParticipantDisplay = (participants) => {
  if (participants && participants.length > 0) {
    const display = participants
      .map((p) => `${get(p, "actor.display")}`)
      .join(", ");

    return display;
  }
};

export const trimStringProperties = (obj) => {
  if (obj instanceof Date) {
    return obj;
  }
  // Check if the value is an object or array
  if (typeof obj === "object" && obj !== null) {
    // If it's an array, iterate over the elements
    if (Array.isArray(obj)) {
      return obj.map((element) => trimStringProperties(element));
    } else {
      // If it's an object, iterate over the properties
      const trimmedObj = {};
      for (const key in obj) {
        // Recursively trim each property
        trimmedObj[key] = trimStringProperties(obj[key]);
      }
      return trimmedObj;
    }
  } else if (typeof obj === "string") {
    // If it's a string, trim it
    return obj.trim();
  } else {
    // Return the value as is for other data types
    return obj;
  }
};

export const createEncounterParam = (encounterReference) => {
  return encounterReference?.reference
    ? { encounter: encounterReference.reference }
    : {};
};

export const createEncounterIdentifierParam = (encounterReference) => {
  return encounterReference?.identifier
    ? { encounter: encounterReference.identifier }
    : {};
};

const parseTextField = (val: Record<string, unknown> | string): string => {
  if (typeof val === "string") {
    return val;
  } else if (val?.display) {
    return val.display as string;
  } else if (val?.text) {
    return val.text as string;
  }

  return "";
};
