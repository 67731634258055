import useAsyncEffect from "use-async-effect";
import MultipleInput from "./components/MultipleInput";
import SingleInput from "./components/SingleInput";
import { Grid } from "@mui/material";
import { debounce } from "lodash";
import { FC, useCallback, useLayoutEffect, useState } from "react";
import { refDataApi } from "../../../api/refdata";
import { useSelector } from "../../../store";
import { isTypeCodeableConcept } from "../../../types/codeableConcept";
import { useTypeAheadInput } from "./hooks/useTypeAheadInput";
import { localOptions } from "./localOptions";

export interface TypeAheadProps {
  fieldLabel: string;
  disabled?: boolean;
  isMultiple?: boolean;
  freeOptions?: boolean;
  onChange: (value: any, reason?: string) => void;
  getValueLabel: (a: any) => string;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  getOptionLabel?: (a: any) => string;
  getOptionValue?: (a: any) => void; // only used for Snomed inputs
  optionType: string;
  value: any;
  subset?: any;
  required?: boolean;
  size?: "small" | "medium";
  variant?: "standard" | "outlined" | "filled";
  disableCloseOnSelect?: boolean;
}

function useStoredOptions(optionType) {
  return useSelector((state) => {
    return state.options[optionType] ? state.options[optionType] : [];
  });
}

const TypeAheadInput: FC<TypeAheadProps> = ({
  fieldLabel,
  disabled = false,
  isMultiple = false,
  freeOptions = false,
  getValueLabel,
  getOptionLabel,
  isOptionEqualToValue,
  getOptionValue,
  onChange,
  required = false,
  value,
  optionType,
  size,
  variant,
  subset,
  disableCloseOnSelect = false,
}) => {
  const storedOptions = useStoredOptions(optionType);

  const { storeInitialValue } = useTypeAheadInput();

  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState(storedOptions);
  const [loading, setLoading] = useState(localOptions[optionType] !== true);
  const [searchString, setSearchString] = useState<string>(inputValue);
  const [valueUpdateCounter, setValueUpdateCounter] = useState<number>(0);

  const [otherInitialCheck, setOtherInitialCheck] = useState<boolean>(false); //change this to initial value check

  useLayoutEffect(() => {
    if (!otherInitialCheck) determineIfValueIsTypeOther(value); // figure out a way to run this once
  }, [value]);

  const determineIfValueIsTypeOther = (value) => {
    const SECOND_VALUE_UPDATE_HAS_HAPPENED = valueUpdateCounter > 1;
    if (value) {
      // Check if option is available in options
      if (Array.isArray(value) && value.length === 1) {
        const IS_CODEABLE_CONCEPT = isTypeCodeableConcept(value[0]);
        const isOther = IS_CODEABLE_CONCEPT
          ? codeableConceptAsOtherCheck(value[0])
          : codingAsOtherCheck(value[0]);

        if (isOther) {
          setOtherInitialCheck(true);
          setInputAndSearch(
            value[0].coding[0].display,
            IS_CODEABLE_CONCEPT ? value[0].coding[0].display : value[0].code,
          );
        }
      }

      if (!Array.isArray(value)) {
        const isOther =
          value?.code && typeof value.code === "string"
            ? value.code.startsWith("other")
            : isTypeCodeableConcept(value)
              ? codeableConceptAsOtherCheck(value)
              : false; // && value.code !== NO_KNOWN_SITUATION_CODE;

        if (isOther) {
          const otherValue = value?.display ?? value?.coding[0]?.display;
          setOtherInitialCheck(true);
          setInputAndSearch(otherValue, false);
        }
      }
    }

    if (SECOND_VALUE_UPDATE_HAS_HAPPENED) {
      setOtherInitialCheck(true);
    }
    setValueUpdateCounter(valueUpdateCounter + 1);
  };

  const codeableConceptAsOtherCheck = (value) => {
    return value &&
      value.coding[0].code &&
      typeof value.coding[0].code === "string"
      ? value.coding[0].code.startsWith("other")
      : false;
  };

  const codingAsOtherCheck = (value) => {
    return value && value.code && typeof value.code === "string"
      ? value.code.startsWith("other")
      : false;
  };

  const isValueTypeOther = (value) => {
    try {
      if (!value || !value?.code) return false;

      if (Array.isArray(value) && value.length === 1) {
        return `${value[0].coding[0].code}`.startsWith("other");
      }

      if (!Array.isArray(value)) {
        return value?.code && typeof value.code === "string"
          ? `${value.code}`.startsWith("other")
          : false;
      }

      return false;
    } catch (e) {
      console.error(value);
    }
  };

  useAsyncEffect(() => {
    if (!localOptions[optionType]) {
      debouncedSearch(searchString);
    }
  }, [searchString]);

  const setInputAndSearch = (value, allowSearch: boolean = true) => {
    setInputValue(value);
    if (allowSearch) {
      setSearchString(value);
    }
  };

  const fetchRemoteOptions = async (input) => {
    try {
      setOptions([]);
      // setLoading(true);
      const options = await refDataApi.searchOptions(
        optionType,
        input ? input.split(" · ")[0] : " ",
        subset,
      );
      setOptions(options);
    } catch (e) {
      console.error(e);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useCallback(debounce(fetchRemoteOptions, 200), []);

  const onItemSelection = (val, reason) => {
    if (val) {
      if (!isMultiple) {
        //this is single section
        onChange(getOptionValue ? getOptionValue(val) : val);
      } else {
        //this is multiple section
        const v = getOptionValue ? val.map((v) => getOptionValue(v)) : val;
        onChange(v, reason);
        debouncedSearch(""); // works for multiple not sure about single
      }
    } else {
      onChange(undefined, reason);
      debouncedSearch(""); // this is affecting clearing the single value
    }
  };

  return (
    <Grid item xs={12}>
      <Grid container alignContent="center" alignItems="center" direction="row">
        <Grid item xs={12}>
          {isMultiple ? (
            <MultipleInput
              value={isValueTypeOther(value) ? undefined : value}
              options={options}
              freeOptions={freeOptions}
              disabled={disabled}
              loading={loading}
              onItemSelection={onItemSelection}
              getOptionValue={getOptionValue}
              setInputValue={setInputAndSearch}
              required={required}
              optionType={optionType}
              fieldLabel={fieldLabel}
              size={size}
              variant={variant}
              isOptionEqualToValue={isOptionEqualToValue}
              storeInitialValue={storeInitialValue}
              disableCloseOnSelect={disableCloseOnSelect}
              getOptionLabel={getOptionLabel}
              getValueLabel={getValueLabel}
            />
          ) : (
            <SingleInput
              value={value ?? ""} // make another check here
              options={options}
              freeOptions={freeOptions}
              disabled={disabled}
              loading={loading}
              onItemSelection={onItemSelection}
              getOptionValue={getOptionValue}
              setInputValue={setInputAndSearch}
              required={required}
              optionType={optionType}
              fieldLabel={fieldLabel}
              size={size}
              variant={variant}
              isOptionEqualToValue={isOptionEqualToValue}
              disableCloseOnSelect={disableCloseOnSelect}
              getOptionLabel={getOptionLabel}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TypeAheadInput;
