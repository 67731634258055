import { Auth0Client } from "@auth0/auth0-spa-js";
import axios from "axios";
import { apiConfig, auth0Config } from "../config";
import { Appointment } from "../types/appointment";
import querySearchEndpoint, {
  queryUpdateEndpoint,
} from "../utils/querySearchEndpoint";
import asArray from "../utils/asArray";
import { Status } from "../slices/options/appointments";

class AppointmentApi {
  private auth0Client: Auth0Client | null = null;

  constructor() {
    this.auth0Client = new Auth0Client({
      redirect_uri: window.location.origin,
      ...auth0Config,
    });
  }
  async getCurrentAppointment(
    appointmentId: string,
    query: Record<string, any>,
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };

      const url = new URL(
        `${apiConfig.appointmentServiceBase}/appointments/${appointmentId}`,
      );
      const response = await querySearchEndpoint(url, query, config);
      return response;
    } catch (e) {
      console.error("Error when retrieving appointment " + JSON.stringify(e));
      throw e;
    }
  }
  async getAppointmentMessages(
    appointmentId: string,
    query?: { [key: string]: any },
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };

      const url = new URL(
        `${apiConfig.appointmentServiceBase}/appointments/messages/${appointmentId}`,
      );
      return await querySearchEndpoint(url, query, config);
    } catch (e) {
      console.error(
        `Error retrieving appointment's messages ${JSON.stringify(e)}`,
      );
      throw e;
    }
  }

  async findAppointments(query: any, signal?: AbortSignal) {
    try {
      if (asArray(query.status, []).length === 0) {
        query.status = Status.filter(
          (e) => !["entered-in-error", "cancelled"].includes(e?.code),
        ).map((e) => e.code);
      }

      const authToken = await this.auth0Client.getTokenSilently();
      const config: Record<string, unknown> = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
      const url = new URL(`${apiConfig.appointmentServiceBase}/appointments`);

      if (signal) {
        config.signal = signal;
      }

      const response = querySearchEndpoint(url, query, config);
      return response;
    } catch (e) {
      console.error("Error when retrieving appointments " + JSON.stringify(e));
      throw e;
    }
  }

  async savedAppointment(appointmentData: Appointment) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.post(
        `${apiConfig.appointmentServiceBase}/appointments`,
        appointmentData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error("Error when saving appointments " + JSON.stringify(e));
      throw e;
    }
  }

  async updateAppointment(
    appointmentId: string,
    appointmentData: Appointment,
    query?: Record<string, any>,
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };

      const url = new URL(
        `${apiConfig.appointmentServiceBase}/appointments/${appointmentId}`,
      );
      const response = await queryUpdateEndpoint(
        url,
        query,
        config,
        appointmentData,
      );
      return response;
    } catch (e) {
      console.error("Error when updating appointment " + JSON.stringify(e));
      throw e;
    }
  }
  //count

  async countAppointment(query: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const url = new URL(
        `${apiConfig.appointmentServiceBase}/appointments/stats/count`,
      );
      const response = querySearchEndpoint(url, query, config);
      return response;
    } catch (e) {
      console.error(
        "Error when retrieving total number of appointments " +
          JSON.stringify(e),
      );
      throw e;
    }
  }
  async getPopularHours(organizationId: string, period: string, query: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const url = new URL(
        `${apiConfig.appointmentServiceBase}/appointments/schedule/popular-hours/${organizationId}/${period}`,
      );
      const response = querySearchEndpoint(url, query, config);
      return response;
    } catch (e) {
      console.error(
        "Error when retrieving total number of appointments " +
          JSON.stringify(e),
      );
      throw e;
    }
  }

  async findHolidays(query) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const url = new URL(`${apiConfig.appointmentServiceBase}/holidays`);
      const response = querySearchEndpoint(url, query, config);
      return response;
    } catch (e) {
      console.error("Error when retrieving holidays " + JSON.stringify(e));
      throw e;
    }
  }

  async findBroadcasts(query: any, signal?: AbortSignal) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config: Record<string, unknown> = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
      const url = new URL(`${apiConfig.appointmentServiceBase}/communications`);
      if (signal) {
        config.signal = signal;
      }
      const response = querySearchEndpoint(url, query, config);
      return response;
    } catch (e) {
      console.error("Error when retrieving broadcasts " + JSON.stringify(e));
      throw e;
    }
  }

  async findBroadcastById(broadcastId: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.appointmentServiceBase}/communications/${broadcastId}`,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error("Error when retrieving broadcast " + JSON.stringify(e));
      throw e;
    }
  }

  async saveBroadcast(broadcastData: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.post(
        `${apiConfig.appointmentServiceBase}/communications`,
        broadcastData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error("Error when saving broadcast " + JSON.stringify(e));
      throw e;
    }
  }

  async updateBroadcast(broadcastId: string, broadcastData: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.put(
        `${apiConfig.appointmentServiceBase}/communications/${broadcastId}`,
        broadcastData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error("Error when updating broadcast " + JSON.stringify(e));
      throw e;
    }
  }

  async deleteBroadcast(broadcastId: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      await axios.delete(
        `${apiConfig.appointmentServiceBase}/communications/${broadcastId}`,
        config,
      );
    } catch (e) {
      console.error("Error when deleting broadcast " + JSON.stringify(e));
      throw e;
    }
  }

  async findFirstAppointmentForPatient(
    patientId: string,
    organizationId: string,
  ) {
    const authToken = await this.auth0Client.getTokenSilently();
    const config = { headers: { Authorization: `Bearer ${authToken}` } };
    const response = await axios.get(
      `${apiConfig.appointmentServiceBase}/appointments/patients/${patientId}/organizations/${organizationId}/first`,
      config,
    );

    return response.data;
  }

  // healthCheck
  async healthCheck() {
    try {
      const response = await axios.get(
        `${apiConfig.appointmentServiceBase}/health`,
      );
      return response.data;
    } catch (e) {
      console.error(
        "Appointment Service health check was unsuccessful" + JSON.stringify(e),
      );
    }
  }
}
//https://medial-scheduling-service.ue.r.appspot.com
export const appointmentApi = new AppointmentApi();
