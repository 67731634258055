export enum QueryKeys {
  ALLERGIES = "allergies",
  APPOINTMENTS = "appointments",
  BROADCASTS = "broadcasts",
  CARE_PLANS = "care_plans",
  COUNT_ENTITIES = "count-entities",
  ENCOUNTERS = "encounters",
  MEDICATION = "medication-requests",
  PATIENTS = "patients",
  PRACTITIONERS = "practitioners",
  PREVIOUS_ENCOUNTERS = "previous_encounters",
  SERVICES = "services",
  APPOINTMENT_DUPLICATE_PATIENTS = "appointment-duplicate-patients",
}
