import { Auth0Client } from "@auth0/auth0-spa-js";
import axios from "axios";
import { apiConfig, auth0Config } from "../config";
import { Patient } from "../types/patient";
import querySearchEndpoint from "../utils/querySearchEndpoint";

class PatientApi {
  private auth0Client: Auth0Client | null = null;

  constructor() {
    this.auth0Client = new Auth0Client({
      redirect_uri: window.location.origin,
      ...auth0Config,
    });
  }

  async getCurrentPatient(patientId: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.patientServiceBase}/patients/${patientId}`,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error(
        `Error when retrieving patient with id: ${patientId}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(
        `Error when retrieving patient with id: ${patientId}. Please try again.`,
      );
    }
  }

  async getPatientsInBulk(query: { patientIds: string[]; flatten: boolean }) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const url = new URL(
        `${apiConfig.patientServiceBase}/patients/contact-information/bulk`,
      );
      const response = await axios.post(url.toString(), query, config);
      if (response.data && Object.keys(response.data).length > 0) {
        return response.data;
      }
    } catch (e) {
      console.error(
        `Error when retrieving patients in bulk. Error: ${JSON.stringify(e)}`,
      );
      throw new Error(
        `Error when retrieving patients in bulk. Please try again.`,
      );
    }
  }

  async findPatientsByOrg(query: any, signal?: AbortSignal) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config: Record<string, unknown> = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
      const url = new URL(`${apiConfig.patientServiceBase}/patients`);

      if (signal) {
        config.signal = signal;
      }

      return await querySearchEndpoint(url, query, config);
    } catch (err) {
      console.log(
        `Error while fetching patients by organization... ${JSON.stringify(
          err,
        )}`,
      );
      throw new Error(
        "An error occured while finding patients by organization...",
      );
    }
  }

  async countPatients(query: { [key: string]: any }) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const url = new URL(
        `${apiConfig.patientServiceBase}/patients/stats/count`,
      );
      const response = querySearchEndpoint(url, query, config);
      return response;
    } catch (e) {
      console.error(
        "Error when retrieving total number of patients " + JSON.stringify(e),
      );
      throw e;
    }
  }
  async getPatientDemographic(
    query: any,
    organizationId: string,
    demographicType: "age" | "location",
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const url = new URL(
        `${apiConfig.patientServiceBase}/patients/stats/demographics/${organizationId}/${demographicType}`,
      );
      const response = querySearchEndpoint(url, query, config);
      return response;
    } catch (e) {
      console.error(
        "Error when retrieving patient demographics " + JSON.stringify(e),
      );
      throw e;
    }
  }

  async findPatients(query: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.patientServiceBase}/patients?q=${query || " "}`,
        config,
      );

      // console.log(secondResponse, "org data");
      if (response.data && response.data.length > 0) {
        //filter records that match organization id
        return response.data;
      }
      return [];
    } catch (e) {
      console.error("Error when retrieving patients " + JSON.stringify(e));
      throw new Error(
        "An error occured while finding  patients. Please try again.",
      );
    }
  }

  async savePatient(formData: any, skipDuplicateCheck: boolean) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.post(
        `${apiConfig.patientServiceBase}/patients?skip-duplicate-check=${skipDuplicateCheck}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error("Error when adding new patient data " + JSON.stringify(e));
      if (e?.response?.data && e.response.data.body) {
        throw {
          message: "Duplicate patient record found",
          body: e.response.data.body,
        };
      } else {
        throw new Error(
          "An error occured while saving the patient's information. Please try again.",
        );
      }
    }
  }

  async updatePatient(id: string, formData: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.put(
        `${apiConfig.patientServiceBase}/patients/${id}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error(
        `Error updating patient information with ID ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(
        "An error occured while updating the patient's information. Please try again.",
      );
    }
  }

  async findIncompletePatients(query: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const url = new URL(
        `${apiConfig.patientServiceBase}/patients/profile/incomplete`,
      );
      return await querySearchEndpoint(url, query, config);
    } catch (e) {
      console.error(
        "Error when retrieving incomplete patients " + JSON.stringify(e),
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async updateIncompletePatient(id: string, formData: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.put(
        `${apiConfig.patientServiceBase}/patients/profile/incomplete/${id}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error(
        `Error updating patient information with ID ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(
        "An error occured while updating the patient's information. Please try again.",
      );
    }
  }
  async findDuplicatePatients(
    query: Patient,
    signal: AbortSignal,
  ): Promise<Patient[]> {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}` },
        params: query, // Not safe to pass all that data as query, send a partial patient type with only the necessary fields
        signal,
      };

      const response = await axios.get(
        `${apiConfig.patientServiceBase}/patients/duplicates`,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error(
        `Error when retrieving duplicate patients. Error: ${JSON.stringify(e)}`,
      );
      throw new Error(
        `Error when retrieving duplicate patients. Please try again.`,
      );
    }
  }
  // healthCheck

  async healthCheck() {
    try {
      const response = await axios.get(
        `${apiConfig.patientServiceBase}/health`,
      );
      return response.data;
    } catch (e) {
      console.error(
        "Patient Service health check was unsuccessful" + JSON.stringify(e),

      );
    }
  }
}

export const patientApi = new PatientApi();
